import Obra from './../models/Obra'

const obrasData: Obra[] = [
  {
    id: 1,
    title: 'Estacionamento Octogonal AOS 1',
    category: 'predial',
    description:
      'Solução condominial: Revitalização e pintura completa da garagem',
    image: [
      './images/estacionamento/estacionamento-fachada.webp',
      './images/estacionamento/estacionamento-01.webp',
      './images/estacionamento/estacionamento-02.webp',
      './images/estacionamento/estacionamento-03.webp',
      './images/estacionamento/estacionamento-04.webp',
      './images/estacionamento/estacionamento-05.webp',
      './images/estacionamento/estacionamento-06.webp',
      './images/estacionamento/estacionamento-07.webp'
    ],
    details:
      'Pintura completa | Identificação e remarcação de vagas | Ambiente seguro, moderno e totalmente funcional'
  },
  {
    id: 2,
    title: 'Academia TecFit',
    category: 'comercial',
    description: 'Solução Comercial: TecFit Brasil unidade Asa Sul',
    image: [
      './images/academia/academia-01.webp',
      './images/academia/academia-02.webp',
      './images/academia/academia-03.webp',
      './images/academia/academia-04.webp',
      './images/academia/academia-05.webp',
      './images/academia/academia-06.webp'
    ],
    details:
      'Desenvolvemos uma solução completa para a unidade da TecFit Brasil na Asa Sul, Brasília, projetada desde o zero para oferecer uma experiência excepcional aos seus membros. Contando com hall de entrada acolhedor, banheiros privativos e espaços amplos para exercícios físicos. Ambiente moderno e funcional para uma experiência fitness completa.'
  },
  {
    id: 3,
    title: 'Residencial Parque Águas Claras',
    category: 'predial',
    description: 'Solução condominial: Reforma Hall Social',
    image: [
      './images/residencialParque/residencialParque-01.webp',
      './images/residencialParque/residencialParque-02.webp',
      './images/residencialParque/residencialParque-03.webp',
      './images/residencialParque/residencialParque-04.webp',
      './images/residencialParque/residencialParque-05.webp'
    ],
    details:
      'Forro de gesso | Pintura | Instalação de luminárias | Impermeabilização de laje superior do condomínio'
  },
  {
    id: 4,
    title: 'Casa Park Way',
    category: 'residencial',
    description: 'Ambiente confortável e moderno',
    image: [
      './images/casaPark/casaPark-01.webp',
      './images/casaPark/casaPark-02.webp',
      './images/casaPark/casaPark-03.webp',
      './images/casaPark/casaPark-04.webp',
      './images/casaPark/casaPark-05.webp',
      './images/casaPark/casaPark-06.webp',
      './images/casaPark/casaPark-07.webp',
      './images/casaPark/casaPark-08.webp'
    ],
    details:
      'Pintura externa e interna da casa | Reforma dos banheiros e lavabos | Construção de escada | Troca de forro de gesso | Construção de parede de cobogo com 5 metros de altura'
  },
  {
    id: 5,
    title: 'Residencial Matheus - Águas Claras',
    category: 'predial',
    description: 'Solução condominial: Revitalização de fachada',
    image: [
      './images/matheus/matheus-01.webp',
      './images/matheus/matheus-02.webp',
      './images/matheus/matheus-03.webp',
      './images/matheus/matheus-04.webp',
      './images/matheus/matheus-05.webp',
      './images/matheus/matheus-06.webp',
      './images/matheus/matheus-07.webp'
    ],
    details:
      'Instalação de pontos de ancoragem com teste de carga | Laudo de resistência dos pontos de ancoragem | Laudo de fachada com demarcação de pastilhas soltas | Reforma na fachada com troca de pastilhas, rejunte e troca de P.U das juntas de dilatação | Lavagem total da fachada | Troca de pedras em granito nas jardineiras em volta do condomínio e na portaria | Polimento, impermeabilização e troca de rejunte no piso térreo | Troca total do telhado | Substituição do porcelanato de todos os corredores.'
  },
  {
    id: 6,
    title: 'Escritório M.A.R.A',
    category: 'comercial',
    description: 'Solução Comercial para Ambientes Corporativos',
    image: [
      './images/mara/MARA-01.webp',
      './images/mara/MARA-02.webp',
      './images/mara/MARA-03.webp',
      './images/mara/MARA-04.webp',
      './images/mara/MARA-05.webp',
      './images/mara/MARA-06.webp',
      './images/mara/MARA-07.webp'
    ],
    details:
      'Modernização da infraestrutura de rede | Substituição de cabos | Montagem de Rack de Servidor | Espaços Funcionais | Sala Central | Sala de Reuniões | Sala do Responsável pelo Escritório | Divisórias de Vidro | Troca de Porcelanato para Vinílico | Pintura Completa'
  },
  {
    id: 7,
    title: 'Varanda SQB - Guara',
    category: 'residencial',
    description: 'Solução residencial: Reforma de varanda',
    image: [
      './images/guara/guara-01.webp',
      './images/guara/guara-02.webp',
      './images/guara/guara-03.webp',
      './images/guara/guara-04.webp',
      './images/guara/guara-05.webp'
    ],
    details:
      'Substituição total de revestimentos | Paredes | Piso | Instalação de bancadas | Blindex de proteção da varanda'
  },
  {
    id: 8,
    title: 'Condomínio SHIP N° 58',
    category: 'individualizacao',
    description: 'Solução: Individualização de Gás',
    image: [
      './images/ship/ship-01.webp',
      './images/ship/ship-02.webp',
      './images/ship/ship-03.webp'
    ],
    details: 'Individualização de gás no condomínio'
  }
]

export default obrasData
