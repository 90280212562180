import { Link } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'

const Header = () => {
  return (
    <nav
      className="navbar justify-content-between navbar-expand-lg bg-body-tertiary"
      id="home"
    >
      <div className="container">
        <div className="d-flex align-items-center">
          <Link to="/" className="navbar-brand m-auto">
            <img
              src="./../images/cropped-dfc-logo.png"
              alt="logo DFC"
              className="w-75"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/" className="nav-link" aria-current="page">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <NavHashLink to="/#servicos" className="nav-link text-primary">
                Serviços
              </NavHashLink>
            </li>
            <li className="nav-item">
              <NavHashLink to="/#destaques" className="nav-link text-primary">
                Destaques
              </NavHashLink>
            </li>
            <li className="nav-item">
              <NavHashLink to="/#projetos" className="nav-link text-primary">
                Projetos
              </NavHashLink>
            </li>
            <li className="nav-item">
              <NavHashLink to="/#sobre" className="nav-link text-primary">
                Sobre Nós
              </NavHashLink>
            </li>
            <li className="nav-item">
              <Link to="/obras" className="nav-link text-primary">
                Obras
              </Link>
            </li>
            <li className="nav-item">
              <NavHashLink to="/#contato" className="nav-link text-primary">
                Contato
              </NavHashLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header
