const Servicos = () => {
  return (
    <section className="container marketing mt-4 mb-4" id="servicos">
      <h2 className="pb-2 mb-4 border-bottom">Nossos serviços</h2>
      <div className="row">
        <div className="mb-5 mb-md-3 col-lg-4 d-flex flex-column align-items-center text-center">
          <div
            className="bg-primary rounded-circle d-flex justify-content-center align-items-center"
            style={{ width: '140px', height: '140px' }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="96"
              height="96"
              fill="white"
              className="bi bi-house"
              viewBox="0 0 16 16"
            >
              <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
            </svg>
          </div>
          <h3 className="fw-normal">Residencial</h3>
          <p className="mb-1">
            Construção e reformas residenciais e processos de construção
            financiada para clientes.
          </p>
          <a className="btn btn-md btn-primary" href="#projetos">
            Saiba Mais &raquo;
          </a>
        </div>
        <div className="mb-5 mb-md-3 col-lg-4 d-flex flex-column align-items-center text-center">
          <div
            className="bg-primary rounded-circle d-flex justify-content-center align-items-center"
            style={{ width: '140px', height: '140px' }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="96"
              height="96"
              fill="white"
              className="bi bi-buildings"
              viewBox="0 0 16 16"
            >
              <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z" />
              <path d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z" />
            </svg>
          </div>
          <h3 className="fw-normal">Predial</h3>
          <p className="mb-1">
            Reformas de fachadas, fiscalizações, laudos, teste de bate fofo,
            juntas de dilatação e outros.
          </p>
          <a className="btn btn-md btn-primary" href="#projetos">
            Saiba Mais &raquo;
          </a>
        </div>
        <div className="mb-5 mb-md-3 col-lg-4 d-flex flex-column align-items-center text-center">
          <div
            className="bg-primary rounded-circle d-flex justify-content-center align-items-center"
            style={{ width: '140px', height: '140px' }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="96"
              height="96"
              fill="white"
              className="bi bi-shop"
              viewBox="0 0 16 16"
            >
              <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5M4 15h3v-5H4zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm3 0h-2v3h2z" />
            </svg>
          </div>
          <h3 className="fw-normal">Comercial</h3>
          <p className="mb-1">
            Reformas e construções de escritórios corporativos, salas comerciais
            e outros
          </p>
          <a className="btn btn-md btn-primary" href="#projetos">
            Saiba Mais &raquo;
          </a>
        </div>
      </div>
    </section>
  )
}

export default Servicos
