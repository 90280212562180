import AguaGas from '../../components/AguaGas'
import Carrossel from '../../components/Carrossel'
import Contato from '../../components/Contato'
import Projetos from '../../components/Projetos'
import Servicos from '../../components/Servicos'
import Sobre from '../../components/Sobre'

const Home = () => (
  <>
    <Carrossel />
    <Servicos />
    <AguaGas />
    <Projetos />
    <Sobre />
    <Contato />
  </>
)

export default Home
