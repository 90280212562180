const Carrossel = () => {
  return (
    <section id="home">
      <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators d-none d-md-flex">
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="./images/carrossel-1.webp"
              alt="edificio carrossel"
              className="img-fluid"
            />
            <div
              className="position-absolute top-0 start-0 w-100 h-100"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            ></div>
            <div className="container d-sm-flex">
              <div className="carousel-caption text-start p-0 p-md-5">
                <h2 className="text-sm">Qualidade & Confiabilidade</h2>
                <p className="opacity-75 d-none d-md-block">
                  Nossa reputação é construída sobre a base sólida da qualidade
                  e confiabilidade.
                </p>
                <p>
                  <a
                    className="btn btn-primary d-none d-md-inline-block"
                    href="#servicos"
                  >
                    Conheça nossos serviços
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="./images/carrossel-2.webp"
              alt="edificio carrossel"
              className="img-fluid"
              style={{ width: '100%', objectFit: 'cover', maxHeight: '700px' }}
            />
            <div
              className="position-absolute top-0 start-0 w-100 h-100"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            ></div>
            <div className="container">
              <div className="carousel-caption text-start p-0 p-md-5 text-md-center">
                <h2>Solicite uma Visita Técnica</h2>
                <p className="opacity-75 d-none d-md-block">
                  Para soluções adaptadas às suas necessidades, estamos à
                  disposição para agendar uma visita técnica.
                </p>
                <p>
                  <a
                    className="btn btn-primary d-none d-md-inline-block"
                    href="#contato"
                  >
                    Entrar em Contato
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="./images/carrossel-3.webp"
              alt="edificio carrossel"
              className="img-fluid"
              style={{ width: '100%', objectFit: 'cover', maxHeight: '700px' }}
            />
            <div
              className="position-absolute top-0 start-0 w-100 h-100"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            ></div>
            <div className="container">
              <div className="carousel-caption text-start p-0 p-md-5 text-md-end">
                <h2>Aqui cada detalhe importa</h2>
                <p className="opacity-75 d-none d-md-block">
                  Cada detalhe é tratado com cuidado e importância para alcançar
                  a excelência.
                </p>
                <p>
                  <a
                    className="btn btn-primary d-none d-md-inline-block"
                    href="#projetos"
                  >
                    Nossos Projetos
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </section>
  )
}

export default Carrossel
