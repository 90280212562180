import CapaObras from '../../components/CapaObras'
import ListaObras from '../../components/ListaObras'

const Obras = () => (
  <>
    <CapaObras />
    <ListaObras />
  </>
)

export default Obras
