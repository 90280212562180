import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { NavHashLink } from 'react-router-hash-link'

const Projetos = () => {
  const navigate = useNavigate()
  const [selectedFilter, setSelectedFilter] = useState<string>('')

  const handleFilterSelection = (filter: string) => {
    setSelectedFilter(filter)
    navigate(`/obras?filter=${filter}`)
  }

  return (
    <section className="container mt-5" id="projetos">
      <h2 className="pb-2 border-bottom">Projetos de excelência</h2>
      <div className="row">
        <div className="d-md-flex flex-md-equal my-md-3 ps-md-3 w-100">
          <div
            className="text-bg-primary me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden animate__animated animate__fadeInUp w-100"
            style={{ cursor: 'pointer', animationDuration: '2s' }}
            onClick={() => handleFilterSelection('individualizacao')}
          >
            <NavHashLink
              to="/obras#listaObras"
              className="text-decoration-none text-light"
            >
              <div className="my-3 py-3">
                <h2 className="display-5">Individualização de Água & Gás</h2>
                <p className="lead">Com fita de led embutida e guarda corpo</p>
              </div>
              <div
                className="mx-auto"
                style={{
                  width: '80%',
                  height: '300px'
                }}
              >
                <img
                  src="./images/guara/guara-01.webp"
                  className="img-fluid rounded-4"
                  alt=""
                />
              </div>
            </NavHashLink>
          </div>

          <div
            className="bg-body-tertiary me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden animate__animated animate__fadeInUp w-100"
            style={{ cursor: 'pointer', animationDuration: '2.2s' }}
            onClick={() => handleFilterSelection('predial')}
          >
            <NavHashLink
              to="/obras#listaObras"
              className="text-decoration-none text-primary"
            >
              <div className="my-3 p-3">
                <h2 className="display-5">Reformas Condominiais</h2>
                <p className="lead">
                  Um ambiente condominial que combina sofisticação e conforto.
                </p>
              </div>
              <div
                className="mx-auto"
                style={{
                  width: '80%',
                  height: '300px',
                  borderRadius: '21px 21px 0 0'
                }}
              >
                <img
                  src="./images/estacionamento/estacionamento-fachada.webp"
                  className="img-fluid rounded-4"
                  alt=""
                  loading="lazy"
                />
              </div>
            </NavHashLink>
          </div>
        </div>

        <div className="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3">
          <div
            className="text-bg-primary me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden animate__animated animate__fadeInUp w-100"
            style={{ cursor: 'pointer', animationDuration: '2.4s' }}
            onClick={() => handleFilterSelection('residencial')}
          >
            <NavHashLink
              to="/obras#listaObras"
              className="text-decoration-none text-light"
            >
              <div className="my-3 py-3">
                <h2 className="display-5">Obras Residenciais</h2>
                <p className="lead">
                  Duplex com decoração em ladrilhos hidráulicos à modernidade
                  das placas de ACM.
                </p>
              </div>
              <div
                className="mx-auto"
                style={{
                  width: '80%',
                  height: '300px',
                  borderRadius: '21px 21px 0 0'
                }}
              >
                <img
                  src="./images/casaPark/casaPark-01.webp"
                  className="img-fluid rounded-4"
                  alt=""
                />
              </div>
            </NavHashLink>
          </div>
          <div
            className="order-md-first bg-body-tertiary me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden animate__animated animate__fadeInUp w-100"
            style={{ cursor: 'pointer', animationDuration: '2.3s' }}
            onClick={() => handleFilterSelection('comercial')}
          >
            <NavHashLink
              to="/obras#listaObras"
              className="text-decoration-none text-primary"
            >
              <div className="my-3 p-3">
                <h2 className="display-5">Obras Comerciais</h2>
                <p className="lead">
                  Ambientes profissionais e acolhedores para colaboradores e
                  clientes.
                </p>
              </div>
              <div
                className="mx-auto"
                style={{
                  width: '80%',
                  height: '300px',
                  borderRadius: '21px 21px 0 0'
                }}
              >
                <img
                  src="./images/mara/MARA-01.webp"
                  className="img-fluid rounded-4"
                  alt=""
                />
              </div>
            </NavHashLink>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Projetos
