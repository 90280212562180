import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Footer from './components/Footer'
import Header from './components/Header'
import EstiloGlobal from './styles'
import './styles/copia-bootstrap.min.css'
import Home from './pages/Home'
import Obras from './pages/Obras'
import { Provider } from 'react-redux'
import store from './store'
import BotaoWhats from './components/BotaoWhats'

function App() {
  return (
    <Provider store={store}>
      <Router>
        <EstiloGlobal />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/obras" element={<Obras />} />
        </Routes>
        <BotaoWhats />
        <Footer />
      </Router>
    </Provider>
  )
}

export default App
