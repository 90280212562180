import { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay } from 'swiper/modules'
import ObraClass from '../../models/Obra'

type Props = ObraClass

const Obra = ({ title, details, description, image, id }: Props) => {
  const [slidePerView, setSlidePerView] = useState(3)

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 720) {
        setSlidePerView(1)
      } else {
        setSlidePerView(3)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="col">
      <div className="card bg-dark text-white cursor-pointer">
        <img src={image[0]} alt="" />
        <div className="card-img-overlay d-flex flex-column justify-content-end p-0">
          <div
            className="w-100 p-3"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
          >
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{description}</p>

            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target={`#modal-${id}`}
            >
              Saiba Mais
            </button>

            <div
              className="modal fade"
              id={`modal-${id}`}
              tabIndex={-1}
              aria-labelledby={`modalLabel-${id}`}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-xl">
                <div className="modal-content text-black">
                  <div className="modal-header">
                    <h2 className="modal-title" id={`modalLabel-${id}`}>
                      {title}
                    </h2>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>{details}</p>
                    <Swiper
                      slidesPerView={slidePerView}
                      navigation
                      loop={true}
                      pagination={{ type: 'fraction' }}
                      scrollbar={{ draggable: true }}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false
                      }}
                      modules={[Autoplay]}
                    >
                      {image.map((img, index) => (
                        <SwiperSlide key={index}>
                          <img
                            src={img}
                            style={{ maxWidth: '350px' }}
                            className="rounded"
                            alt={`Slide ${index + 1}`}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="modal-footer">
                    <a
                      href="https://api.whatsapp.com/send?phone=5561981657327"
                      target="_blank"
                      className="btn btn-primary"
                      rel="noreferrer noopener"
                    >
                      Entrar em contato
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Obra
