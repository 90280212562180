import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Obra from './../../models/Obra'
import obrasData from '../../dados/data'

type ObrasState = {
  itens: Obra[]
}

const initialState: ObrasState = {
  itens: obrasData
}

const ObrasSlice = createSlice({
  name: 'Obras',
  initialState,
  reducers: {
    remover: (state, action: PayloadAction<number>) => {
      state.itens = [
        ...state.itens.filter((Obra) => Obra.id !== action.payload)
      ]
    },
    editar: (state, action: PayloadAction<Obra>) => {
      const indexDoObra = state.itens.findIndex(
        (c) => c.id === action.payload.id
      )

      if (indexDoObra >= 0) {
        state.itens[indexDoObra] = action.payload
      }
    },
    cadastrar: (state, action: PayloadAction<Obra>) => {
      const ObraJaExiste = state.itens.find(
        (Obra) =>
          Obra.title.toLowerCase() === action.payload.title.toLowerCase()
      )

      if (ObraJaExiste) {
        alert('já existe uma Obra com esse nome')
      } else {
        state.itens.push(action.payload)
      }
    }
  }
})

export const { remover, editar, cadastrar } = ObrasSlice.actions

export default ObrasSlice.reducer
