import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { RootReducer } from '../../store'
import Obra from './../Obra'

const ListaObras = () => {
  const { itens } = useSelector((state: RootReducer) => state.obras)
  const [filteredItens, setFilteredItens] = useState([...itens])
  const [selectedCategory, setSelectedCategory] = useState('')

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const filter = queryParams.get('filter')

  useEffect(() => {
    window.scrollTo(0, 0)
    setFilteredItens(itens)

    if (filter) {
      filterByCategory(filter)
    }
  }, [itens, filter])

  const filterByCategory = (category: string) => {
    setSelectedCategory(category)
    if (category === '') {
      setFilteredItens([...itens])
    } else {
      const filtered = itens.filter((obra) => obra.category === category)
      setFilteredItens(filtered)
    }
  }

  const emptyMessage = () => {
    if (selectedCategory !== '' && filteredItens.length === 0) {
      return <p>Não há obras nesta categoria.</p>
    }
  }

  return (
    <div className="album py-5 bg-body-tertiary" id="listaObras">
      <div className="container">
        <h2 className="pb-2 border-bottom mb-4">
          Confira as nossas últimas obras
        </h2>
        <div
          className="btn-group mb-3 d-flex flex-wrap"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <button
            type="button"
            className={`btn btn-outline-primary ${
              selectedCategory === '' ? 'active' : ''
            }`}
            onClick={() => filterByCategory('')}
          >
            Todos
          </button>
          <button
            type="button"
            className={`btn btn-outline-primary ${
              selectedCategory === 'residencial' ? 'active' : ''
            }`}
            onClick={() => filterByCategory('residencial')}
          >
            Residencial
          </button>
          <button
            type="button"
            className={`btn btn-outline-primary ${
              selectedCategory === 'predial' ? 'active' : ''
            }`}
            onClick={() => filterByCategory('predial')}
          >
            Condominial
          </button>
          <button
            type="button"
            className={`btn btn-outline-primary ${
              selectedCategory === 'comercial' ? 'active' : ''
            }`}
            onClick={() => filterByCategory('comercial')}
          >
            Comercial
          </button>
          <button
            type="button"
            className={`btn btn-outline-primary ${
              selectedCategory === 'individualizacao' ? 'active' : ''
            }`}
            onClick={() => filterByCategory('individualizacao')}
          >
            Individualização de Água e Gás
          </button>
        </div>

        {emptyMessage()}
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
          {filteredItens.map((o) => (
            <div key={o.title} className="col">
              {' '}
              <Obra
                id={o.id}
                title={o.title}
                category={o.category}
                description={o.description}
                details={o.details}
                image={o.image}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ListaObras
