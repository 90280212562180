import styled, { createGlobalStyle } from 'styled-components'
import variaveis from './variaveis'

const EstiloGlobal = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    list-style: none;
  }

  a.nav-link {
    color: ${variaveis.corPrincipal};
    font-weight: bold;

    &:active {
      color: ${variaveis.corSecundaria};
    }
  }

`

export const Container = styled.div`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  margin-top: 24px;
  padding: 0 40px;
`

export default EstiloGlobal
