import { VideoContainer, VideoBackground, Overlay, TextOverlay } from './styles'

const CapaObras = () => {
  return (
    <div className="position-relative overflow-hidden p-3 p-md-5 text-center">
      <div className="col-md-6 p-lg-5 mx-auto my-5 color-light">
        <VideoContainer>
          <VideoBackground
            src="./images/cover-obras.webm"
            autoPlay
            loop
            muted
          />
          <Overlay />
        </VideoContainer>
        <TextOverlay>
          <h1 className="display-3 fw-bold">Obras Executadas</h1>
          <div className="d-none d-md-block">
            <h3 className="fw-normal mb-3">
              Em nossas mãos, a construção vai além da edificação física, é um
              processo que molda o futuro e cria memórias.
            </h3>
          </div>
        </TextOverlay>
      </div>
    </div>
  )
}

export default CapaObras
